import { gql } from '@apollo/client/core';

const USER_ACCEPT = gql`
  mutation malmonUserAccept($token: String!) {
    malmon_user_accept(token: $token)
  }
`;

const USER_DENY = gql`
  mutation malmonUserDeny($token: String!) {
    malmon_user_deny(token: $token)
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { USER_ACCEPT, USER_DENY };
