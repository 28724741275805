import { gql } from '@apollo/client/core';

const PIN_RESET = gql`
  mutation malmonPinReset($email: String!) {
    malmon_pin_reset(email: $email)
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { PIN_RESET };
