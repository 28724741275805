/* eslint-disable react/prop-types */
import { styled } from '@mui/material/styles';

const InnerContainerPublic = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0 auto',
  width: 'auto',
  position: 'relative',
  [theme.breakpoints.up('sm')]: {
    maxWidth: 360,
    width: 'auto'
  }
}));

export default InnerContainerPublic;
