/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import UploadForm from '../../molecules/UploadForm';

const HeaderText = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold
  })
);

const SubText = styled((props) => <Typography {...props} />)({
  color: 'rgba(255, 255, 255, 0.7)'
});

const BorderBox = styled((props) => <Box {...props} />)({
  padding: '36px 24px',
  width: '100%',
  position: 'relative'
});

const UploadPageForm = () => {
  return (
    <>
      <Box px={2}>
        <HeaderText variant="h5" align="center" color="primary" gutterBottom>
          Upload Suspected Malware
        </HeaderText>
        <SubText align="center" variant="body2">
          Once you submit your malware, you will receive two emails. The first
          contains your confirmation of submission, and the second contains the
          results of your sample analysis.
        </SubText>
      </Box>
      <BorderBox>
        <UploadForm />
      </BorderBox>
    </>
  );
};

export default UploadPageForm;
