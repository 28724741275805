import createDataContext from './createDataContext';

const userReducer = (state, action) => {
  switch (action.type) {
    case 'set_user':
      return action.payload;
    case 'remove_user':
      return {};
    default:
      return state;
  }
};

const setCurrentUser = (dispatch) => {
  return (user) => {
    window.localStorage.setItem('user', JSON.stringify(user));
    dispatch({ type: 'set_user', payload: user });
  };
};

const removeCurrentUser = (dispatch) => {
  return () => {
    dispatch({ type: 'remove_user' });
  };
};

const initialUserData = JSON.parse(window.localStorage.getItem('user'));

export const { Context, Provider } = createDataContext(
  userReducer,
  { setCurrentUser, removeCurrentUser },
  initialUserData
);
