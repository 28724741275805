import * as React from 'react';
import ResetPinPageForm from '../../organisms/ResetPinPageForm';
import Footer from '../../organisms/Footer';
import Header from '../../organisms/Header';
import InnerContainerPublic from '../../organisms/InnerContainerPublic';
import WrapperContainerPublic from '../../organisms/Header/WrapperContainerPublic';

const LoginPage = () => {
  return (
    <WrapperContainerPublic component="div" maxWidth="false" disableGutters>
      <Header />
      <InnerContainerPublic>
        <ResetPinPageForm />
      </InnerContainerPublic>
      <Footer />
    </WrapperContainerPublic>
  );
};

export default LoginPage;
