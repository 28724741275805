import { gql } from '@apollo/client/core';

const USER_LOGIN = gql`
  mutation malmonUserLogin(
    $email: String!
    $pin_code: String!
    $recaptcha: String!
  ) {
    malmon_user_login(
      email: $email
      pin_code: $pin_code
      recaptcha: $recaptcha
    ) {
      id
      name
      email
      phone
      last_login
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { USER_LOGIN };
