import React from 'react';
import { Typography } from '@mui/material';

const RegistrationConfirmation = () => {
  return (
    <Typography variant="body2" align="center">
      Thank you for registering an account with Malware Monster, You will
      receive an email with further instructions.
    </Typography>
  );
};

export default RegistrationConfirmation;
