import Cookies from 'js-cookie';
import createDataContext from './createDataContext';

let authToken;

const authReducer = (state, action) => {
  switch (action.type) {
    case 'set_auth':
      return { token: action.payload };
    case 'logout':
      return {};
    default:
      return state;
  }
};

const setAuthToken = (dispatch) => {
  return (token) => {
    authToken = Cookies.get('refresh_token');
    dispatch({ type: 'set_auth', payload: token });
  };
};

const logout = (dispatch) => {
  return async () => {
    await Cookies.remove('refresh_token');
    await Cookies.remove('token');
    await localStorage.clear();
    await dispatch({ type: 'logout' });
    window.location.reload(true);
  };
};

const logoutWORefresh = (dispatch) => {
  return async () => {
    await Cookies.remove('refresh_token');
    await Cookies.remove('token');
    await localStorage.clear();
    await dispatch({ type: 'logout' });
  };
};

export const { Context, Provider } = createDataContext(
  authReducer,
  { setAuthToken, logout, logoutWORefresh },
  { token: authToken }
);
