import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Footer from '../../organisms/Footer';
import Header from '../../organisms/Header';
import InnerContainerPublic from '../../organisms/InnerContainerPublic';
import WrapperContainerPublic from '../../organisms/Header/WrapperContainerPublic';
import { USER_ACCEPT, USER_DENY } from './mutations';

const NewUserPage = () => {
  const [message, setMessage] = useState('');
  const { action, token } = useParams();

  const mutation = action === 'accept' ? USER_ACCEPT : USER_DENY;
  const resultName =
    action === 'accept' ? 'malmon_user_accept' : 'malmon_user_deny';
  const [newUserAction] = useMutation(mutation);

  const performAction = async () => {
    await newUserAction({
      variables: { token }
    })
      .then(({ data }) => {
        setMessage(data[resultName]);
        return true;
      })
      .catch((e) => {
        if (e.graphQLErrors[0]) setMessage(e.graphQLErrors[0].message);
        return false;
      });
    return false;
  };

  useEffect(() => {
    // check incoming parameters
    if (['accept', 'deny'].includes(action) && token.length === 64) {
      performAction();
    } else {
      setMessage('Invalid Call');
    }
  }, []);

  return (
    <WrapperContainerPublic component="div" maxWidth="false" disableGutters>
      <Header />
      <InnerContainerPublic>
        <h1>{message}</h1>
      </InnerContainerPublic>
      <Footer />
    </WrapperContainerPublic>
  );
};

export default NewUserPage;
