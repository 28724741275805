import { gql } from '@apollo/client/core';

const USER_REGISTER = gql`
  mutation malmonUserRegister(
    $name: String!
    $email: String!
    $organization: String!
    $occupation: String!
    $phone: String!
    $recaptcha: String!
  ) {
    malmon_user_register(
      name: $name
      email: $email
      organization: $organization
      occupation: $occupation
      phone: $phone
      recaptcha: $recaptcha
    )
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { USER_REGISTER };
