import React, { useReducer } from 'react';

export default (reducer, actions, initialState) => {
  const Context = React.createContext();

  // eslint-disable-next-line react/prop-types
  const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const boundActions = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in actions) {
      // doing this the (dispatch) actually passes dispatch to each of the "actions" within the
      // context.
      boundActions[key] = actions[key](dispatch);
    }

    return (
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      <Context.Provider value={{ state, ...boundActions }}>
        {children}
      </Context.Provider>
    );
  };

  return { Context, Provider };
};
