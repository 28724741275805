/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, lazy, Suspense, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik, FormikProvider } from 'formik';
import {
  TextField,
  Button,
  Box,
  FormControlLabel,
  CircularProgress,
  Alert,
  Checkbox,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { UPLOAD_CREATE } from './mutations';
import { Context as authContext } from '../../../context/auth';

const FileUpload = lazy(() => import('../FileUpload'));

const CheckBoxFormLabel = styled((props) => <FormControlLabel {...props} />)({
  alignItems: 'normal',
  marginRight: 0
});

const CheckBox = styled((props) => <Checkbox {...props} />)({
  alignItems: 'flex-start'
});

const ErrorAlert = styled((props) => <Alert {...props} />)({
  marginBottom: 16
});

const HighContrastTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#444',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: 'bold',
    padding: '1em 1.5em'
  }
}));

const validationSchema = yup.object({
  summary: yup.string().trim().required('Please Enter a Summary'),
  hasFiles: yup.bool().oneOf([true], 'Files must be Attached to Continue'),
  acceptTerms: yup
    .bool()
    .oneOf([true], 'Accept Information Sharing Terms & Conditions is required')
});

const UploadForm = () => {
  const [uploadFiles, setUploadFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { logout: logoutUser } = useContext(authContext);

  // set initial default blank form values
  const initialBlankValues = {
    summary: '',
    hasFiles: false,
    acceptTerms: false
  };

  const [uploadCreate] = useMutation(UPLOAD_CREATE);

  const formik = useFormik({
    initialValues: initialBlankValues,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setErrorMessage('');

      // process file attachments into base64 data
      const processedAttachments = [];
      if (uploadFiles?.length > 0) {
        const filePromises = uploadFiles.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsBinaryString(file);

            reader.onload = (e) => {
              processedAttachments.push({
                name: file.name,
                size: file.size,
                type: file.type,
                base64: btoa(e.target.result)
              });
              resolve();
            };

            reader.onerror = () => {
              // eslint-disable-next-line no-console
              console.log('Error processing file');
              reject();
            };
          });
        });

        // process all files
        await Promise.all(filePromises);
      }

      await uploadCreate({
        variables: {
          summary: values.summary,
          relationshipType: 'malmon_malware',
          files: processedAttachments
        }
      })
        .then(() => {
          logoutUser();
          navigate(`/success`, { replace: true });
        })
        // err
        .catch((e) => {
          formik.setSubmitting(false);
          setLoading(false);
          if (e.graphQLErrors[0]) setErrorMessage(e.graphQLErrors[0].message);
        });
    }
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    isValid
  } = formik;

  const collectFiles = (files) => {
    setFieldValue('hasFiles', !!files?.length);
    setUploadFiles(files);
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <Box py={3}>
          <HighContrastTooltip
            title="State your suspicions about this potential malware file"
            placement="top"
          >
            <TextField
              id="summary"
              name="summary"
              label="Summary (Can Contain Multiple Lines)"
              placeholder="Give a Brief Description of Files"
              multiline
              variant="standard"
              required
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              value={values.summary}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.summary ? errors.summary : ''}
              error={touched.summary && Boolean(errors.summary)}
            />
          </HighContrastTooltip>
        </Box>

        <Suspense fallback="">
          <FileUpload collectFiles={collectFiles} parentFiles={uploadFiles} />
        </Suspense>
        <Box mb={3} mt={3}>
          <CheckBoxFormLabel
            control={
              <CheckBox
                inputProps={{
                  'aria-label': 'Accept Submit Terms',
                  'data-testid': 'accept-terms-check'
                }}
                sx={{
                  '&:hover': { bgcolor: 'transparent' }
                }}
                disableRipple
              />
            }
            name="acceptTerms"
            onChange={handleChange}
            label="The information you are about to submit will be shared with Malware Monster and its partners for analysis. Please confirm that you are NOT sharing Personally Identifiable Information, Protected Health Information, or Other Sensitive Information."
          />
        </Box>

        {errorMessage && (
          <ErrorAlert
            severity="error"
            variant="filled"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorMessage(null);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorMessage}
          </ErrorAlert>
        )}

        <Button
          type="submit"
          variant="contained"
          disabled={!isValid || isSubmitting}
          color="primary"
          fullWidth
        >
          {loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            'Upload Malware'
          )}
        </Button>
      </form>
    </FormikProvider>
  );
};

export default UploadForm;
