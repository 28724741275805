import { gql } from '@apollo/client/core';

const USER_VERIFY = gql`
  query userVerify {
    malmon_user_verify
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { USER_VERIFY };
