import React from 'react';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
import Footer from '../../organisms/Footer';
import UploadPageForm from '../../organisms/UploadPageForm/UploadPageForm';

const WrapperContainer = styled(Container)({
  backgroundColor: '#212121',
  colorScheme: 'dark'
});

const InnerContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0 auto',
  padding: '36px 0',
  width: 'auto',
  position: 'relative',
  maxWidth: '1000px'
});

const FormPage = () => {
  return (
    <WrapperContainer component="div" maxWidth="false" disableGutters>
      <InnerContainer>
        <UploadPageForm />
      </InnerContainer>
      <Footer />
    </WrapperContainer>
  );
};

export default FormPage;
