import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

const TextBox = styled(Box)({
  padding: '48px 24px'
});

const SuccessMessage = () => (
  <TextBox>
    <Typography align="center">
      Thank You for your submitting your files to Malware Monster.
      <br />
      <br />
      Please watch your registered email account for results.
    </Typography>
  </TextBox>
);
export default SuccessMessage;
