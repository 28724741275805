/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, lazy, Suspense } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Link } from '@mui/material';

// const TermsOfService = lazy(() => import('../TermsOfServiceDialog'));
const SupportForm = lazy(() => import('../SupportForm'));

const InfoContainer = styled(Box)({
  display: 'flex',
  flexFlow: 'row wrap',
  width: '100%',
  maxWidth: '326px',
  padding: '0 12px 36px'
});

const Box1 = styled(Box)({
  flexBasis: '50%',
  flexGrow: 0,
  maxWidth: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end'
});

// const Box2 = styled(Box)({
//   flexBasis: '50%',
//   flexGrow: 0,
//   maxWidth: '50%',
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'flex-end',
//   alignItems: 'flex-end'
// });

const LoginFormHelp = () => {
  // const [termsOfServiceOpen, setTermsOfServiceOpen] = useState(false);
  const [supportModalOpen, setSupportModalOpen] = useState(false);

  // const handleOpenTermsOfService = () => {
  //   setTermsOfServiceOpen(true);
  // };

  // const handleTermsOfServiceClose = () => {
  //   setTermsOfServiceOpen(false);
  // };

  const handleSupportFormClose = () => {
    setSupportModalOpen(false);
  };

  const handleSupportFormOpen = () => {
    setSupportModalOpen(true);
  };

  return (
    <>
      <InfoContainer>
        <Box1>
          <Typography variant="body2">Need Help?</Typography>
          <Link
            component="button"
            variant="body2"
            underline="hover"
            onClick={handleSupportFormOpen}
          >
            Contact Our Support &#8594;
          </Link>
        </Box1>
        {
          //   <Box2>
          //     <Link
          //       component="button"
          //       variant="body2"
          //       underline="hover"
          //       onClick={handleOpenTermsOfService}
          //     >
          //       Terms of Service &#8594;
          //     </Link>
          //   </Box2>
        }
      </InfoContainer>
      {
        // {termsOfServiceOpen && (
        //   <Suspense fallback="">
        //     <TermsOfService
        //       open={termsOfServiceOpen}
        //       handleClose={handleTermsOfServiceClose}
        //     />
        //   </Suspense>
        // )}
      }
      {supportModalOpen && (
        <Suspense fallback="">
          <SupportForm
            open={supportModalOpen}
            handleClose={handleSupportFormClose}
          />
        </Suspense>
      )}
    </>
  );
};

export default LoginFormHelp;
