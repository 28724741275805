import { gql } from '@apollo/client/core';

const UPLOAD_CREATE = gql`
  mutation uploadCreate(
    $summary: String!
    $relationshipType: String!
    $files: [FileAttachment!]
  ) {
    malmon_upload_create(
      summary: $summary
      relationshipType: $relationshipType
      files: $files
    )
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { UPLOAD_CREATE };
