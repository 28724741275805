/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { styled } from '@mui/material/styles';
import { IMaskInput } from 'react-imask';
import {
  TextField,
  Button,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import {
  MailOutline,
  PersonOutline,
  AccountBalanceOutlined,
  PhoneIphoneOutlined,
  WorkOutline
} from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { USER_REGISTER } from './mutations';

const ReCAPTCHABox = styled('div')({
  marginTop: '8px',
  marginBottom: '16px'
});
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const validationSchema = yup.object({
  name: yup
    .string('Enter your full name')
    .max(100, 'Must be under 100 characters')
    .trim()
    .required('Full name is required'),
  organization: yup
    .string('Enter your organization')
    .max(100, 'Must be under 100 characters')
    .trim()
    .required('Organization is required'),
  occupation: yup
    .string('Enter your organization')
    .max(100, 'Must be under 100 characters')
    .trim()
    .required('Occupation is required'),
  phone: yup
    .string('Enter your phone number')
    .min(14, 'Invaild phone number')
    .max(15, 'Must be under 15 characters')
    .trim()
    .required('Phone number is required'),
  email: yup
    .string('Enter your email')
    .max(100, 'Must be under 100 characters')
    .trim()
    .email('Enter a valid email')
    .required('Email is required'),
  recaptcha: yup.string().required()
});

const RegisterForm = ({ setRegisterConfirmation }) => {
  const [disableSubmit, setDisableSubmit] = useState(true);
  const recaptchaRef = useRef(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [registerUser] = useMutation(USER_REGISTER);

  const formik = useFormik({
    initialValues: {
      name: '',
      organization: '',
      email: '',
      recaptcha: '',
      phone: '',
      occupation: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      await registerUser({
        variables: {
          name: values.name,
          email: values.email.toLowerCase(),
          organization: values.organization,
          phone: values.phone,
          occupation: values.occupation,
          recaptcha: values.recaptcha
        }
      })
        .then(({ data }) => {
          formik.setSubmitting(false);
          if (data.malmon_user_register) {
            setRegisterConfirmation(true);
          }
          setLoading(false);
        })
        // err
        .catch((e) => {
          formik.setSubmitting(false);
          if (e.graphQLErrors[0]) setError(e.graphQLErrors[0].message);
          recaptchaRef.current.reset();
          setLoading(false);
        });
    }
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        required
        id="name"
        label="Full Name"
        name="name"
        value={formik.values.name}
        margin="normal"
        fullWidth
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonOutline
                sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                fontSize="small"
              />
            </InputAdornment>
          )
        }}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />
      <TextField
        required
        id="organization"
        label="Organization"
        name="organization"
        value={formik.values.organization}
        margin="normal"
        fullWidth
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountBalanceOutlined
                sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                fontSize="small"
              />
            </InputAdornment>
          )
        }}
        error={
          formik.touched.organization && Boolean(formik.errors.organization)
        }
        helperText={formik.touched.organization && formik.errors.organization}
      />
      <TextField
        required
        id="occupation"
        label="Occupation"
        name="occupation"
        value={formik.values.occupation}
        margin="normal"
        fullWidth
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <WorkOutline
                sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                fontSize="small"
              />
            </InputAdornment>
          )
        }}
        error={formik.touched.occupation && Boolean(formik.errors.occupation)}
        helperText={formik.touched.occupation && formik.errors.occupation}
      />
      <TextField
        required
        id="email"
        label="Email"
        name="email"
        value={formik.values.email}
        margin="normal"
        fullWidth
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MailOutline
                sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                fontSize="small"
              />
            </InputAdornment>
          )
        }}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <TextField
        required
        id="phone"
        label="Phone"
        name="phone"
        placeholder="(__) ___-____"
        value={formik.values.phone}
        margin="normal"
        fullWidth
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        InputProps={{
          inputComponent: TextMaskCustom,
          startAdornment: (
            <InputAdornment position="start">
              <PhoneIphoneOutlined
                sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                fontSize="small"
              />
            </InputAdornment>
          )
        }}
        error={formik.touched.phone && Boolean(formik.errors.phone)}
        helperText={formik.touched.phone && formik.errors.phone}
      />
      <ReCAPTCHABox>
        <ReCAPTCHA
          sitekey={`${process.env.REACT_APP_SITE_KEY_RECAPTCHA}`}
          onChange={(value) => {
            formik.setFieldValue('recaptcha', value);
            formik.setSubmitting(false);
            setDisableSubmit(false);
          }}
          name="recaptcha"
          theme="dark"
          ref={recaptchaRef}
          data-testid="mock-v2-captcha-element"
        />
      </ReCAPTCHABox>
      <Button
        fullWidth
        variant="contained"
        type="submit"
        disabled={disableSubmit}
      >
        {loading ? <CircularProgress color="inherit" size={20} /> : 'Sign Up'}
      </Button>
      {error && (
        <Alert severity="error" variant="filled">
          {error}
        </Alert>
      )}
    </form>
  );
};

export default RegisterForm;
