import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import MalMonLogoFile from '../../../assets/images/MalMonLogo.png';

const LogoBox = styled(Box)({
  maxWidth: '138px',
  width: '100%',
  margin: '32px auto 8px auto'
});

const MalMonLogo = () => (
  <img
    style={{ width: 138, height: 150 }}
    src={MalMonLogoFile}
    alt="Malware Monster"
  />
);
const MalMonLogoBox = () => (
  <LogoBox>
    <MalMonLogo />
  </LogoBox>
);

export default MalMonLogoBox;
